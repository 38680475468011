import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

interface LoginProps {
  login: ( username:string, password:string ) => void;
}

const useStyles = createUseStyles({
  box: {
    borderRadius: '5px',
    maxWidth: '200px',
    border: '1px solid var(--color-text-2)',
    margin: '30px auto',
  },
  title: {
    fontSize: '18px',
    lineHeight: '40px',
    height: '40px',
    padding: '0px 15px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: 'var(--color-bkgrnd-yellow)',
  },
  inputlabel: {
    lineHeight: '20px',
    height: '20px',
    padding: '5px 15px 0px 15px',
    fontSize: '14px',
  },
  input: {
    lineHeight: '40px',
    height: '40px',
    
    padding: '3px 15px 10px 15px',
    '& input': {
      width: '100%'
    }
  },
  but: {
    lineHeight: '40px',
    height: '40px',
    textAlign: 'center',
  }
  
});

function Login( props: LoginProps ) {
  const { login } = props;
  const css = useStyles();
  const [ name, setName ] = useState( '' );
  const [ pswd, setPswd ] = useState( '' );
  
  const [canSubmit, setCanSubmit ] = useState( false );

  useEffect(() => {
    setPswd( '' );
    setName( '' );
  }, []);

  useEffect(() => {
    if ( name.length > 0 && pswd.length > 0 ) {
      setCanSubmit( true );
    }
    else {
      setCanSubmit( false );
    }
  }, [name, pswd]);

  const handleNameChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    setName( e.target.value );
  };

  const handlePswdChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    setPswd( e.target.value );
  };

  const handleSubmit = ( e: React.MouseEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    if ( canSubmit ) {
      login( name, pswd );
    }
  };

  const handleSubmitKey = ( e: React.KeyboardEvent<HTMLDivElement> ) => {
    if ( e.key === 'Enter' ) {
      e.stopPropagation();
      e.preventDefault();
      if ( canSubmit ) {
        login( name, pswd );
      }
    }
  };

  return (
    <div>
      <div className={css.box} onKeyDown={handleSubmitKey}>
        <div className={css.title}>Login</div>
        <div className={css.inputlabel}>Username:</div>
        <div className={css.input}><input type="text" value={name} onChange={handleNameChange} placeholder="username"/></div>
        <div className={css.inputlabel}>Password:</div>
        <div className={css.input}><input type="password" value={pswd} onChange={handlePswdChange} placeholder="password"/></div>
        <div className={css.but}><input type="button" disabled={!canSubmit} value="Submit" onClick={handleSubmit}/></div>
      </div>
    </div>
  )
}

export default Login;

