import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { postRequest, patchRequest, deleteRequest } from '../../req';
import ErrorDlg from '../ErrorDlg';

interface PasswordItemProps {
  item?: PasswordsInterface;
  close: () => void;
}

export interface PasswordsInterface {
  id?: number;
  userid?: number;
  title: string;
  url: string;
  username: string;
  password: string;
  accountno: string;
  challenge1: string;
  challenge2: string;
  notes: string;
  email: string;
}

const useStyles = createUseStyles({
  wrap: {
    position: 'fixed',
    zIndex: 1,
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    backgroundColor: 'var(--color-bkgrnd-salmon-hover)',
  },
  dialog: {
    margin: '60px auto',
    maxWidth: '500px',
    maxHeight: 'calc(100% - 120px)',
    backgroundColor: 'var(--color-bkgrnd-salmon)',
    border: '1px solid var(--color-bkgrnd-pink)',
    borderRadius: '5px',
    padding: '15px',
    overflowY: 'scroll'
  },
  inputlabel: {
    lineHeight: '20px',
    height: '20px',
    padding: '5px 15px 0px 15px',
    fontSize: '14px',
  },
  input: {
    lineHeight: '40px',
    height: '40px',
    padding: '3px 15px 10px 15px',
    '& input': {
      width: '100%'
    }
  },
  but: {
    lineHeight: '40px',
    height: '40px',
    textAlign: 'center',
    '& input': {
      margin: '0px 15px'
    }
  },
  title: {
    fontSize: '18px',
    lineHeight: '40px',
    height: '40px',
    padding: '0px 15px',
    // borderTopLeftRadius: '5px',
    // borderTopRightRadius: '5px',
    // backgroundColor: 'var(--color-bkgrnd-yellow)',
    borderBottom: '1px solid var(--color-bkgrnd-pink)',
  },
  inputtext: {
    height: '100px',
    margin: '3px 15px 10px 15px',
    width: 'calc(100% - 30px)',
    boxSizing: 'border-box',
    resize: 'none',
    
  }
});

function PasswordItem( props: PasswordItemProps ) {
  const css = useStyles();
  const [ title, setTitle ] = useState( '' );
  const [ url, setUrl ] = useState( '' );
  const [ username, setUsername ] = useState( '' );
  const [ password, setPassword ] = useState( '' );
  const [ accountno, setAccountno ] = useState( '' );
  const [ challenge1, setChallenge1 ] = useState( '' );
  const [ challenge2, setChallenge2 ] = useState( '' );
  const [ notes, setNotes ] = useState( '' );
  const [ email, setEmail ] = useState( '' );
  const [ err, setErr ] = useState( '' );

  const [ canSubmit, setCanSubmit ] = useState( false );

  useEffect(() => {
    if ( props.item ) {
      const i: PasswordsInterface = props.item;
      setTitle( i.title );
      setUrl( i.url );
      setUsername( i.username );
      setPassword( i.password );
      setAccountno( i.accountno );
      setChallenge1( i.challenge1 );
      setChallenge2( i.challenge2 );
      setNotes( i.notes );
      setEmail( i.email );
    }
  }, []);

  useEffect(() => {
    if ( props.item ) {
      const i: PasswordsInterface = props.item;
      if ( i.title !== title 
        || i.url !== url
        || i.username !== username
        || i.password !== password
        || i.accountno !== accountno
        || i.challenge1 !== challenge1
        || i.challenge2 !== challenge2
        || i.notes !== notes
        || i.email !== email ) {
          setCanSubmit( true );
        }
        else {
          setCanSubmit( false );
        }
    }
    else {
      if (  title.length > 0 
        ||  url.length > 0
        ||  username.length > 0
        ||  password.length > 0
        ||  accountno.length > 0
        ||  challenge1.length > 0
        ||  challenge2.length > 0
        ||  notes.length > 0
        ||  email.length > 0 ) {
          setCanSubmit( true );
        }
        else {
          setCanSubmit( false );
        }

    }
  },[ title, url, username, password, accountno, challenge1, challenge2, email, notes ]);

  const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    const lookup = {
      title: setTitle,
      url: setUrl,
      username: setUsername,
      password: setPassword,
      accountno: setAccountno,
      challenge1: setChallenge1,
      challenge2: setChallenge2,
      email: setEmail,
      notes: setNotes
    };
    //@ts-ignore
    const fn = lookup[ e.target.id  ];
    fn( e.target.value );
  };

  const handleTextChange = ( e: React.ChangeEvent<HTMLTextAreaElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    setNotes( e.target.value );
  };

  const handleCancel = ( e: React.MouseEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    props.close();
  }

  const handleCancelBackdrop = ( e: React.MouseEvent<HTMLDivElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    props.close();
  }

  const handleStopBubble = ( e: React.MouseEvent<HTMLDivElement> ) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const handleSubmit = ( e: React.MouseEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();

    if ( canSubmit && props.item ) {
      const updated = Object.assign({}, props.item, {
        title, username, password, challenge1, challenge2, notes, email, accountno, url
      });

      patchRequest( `/api/passwords/${props.item.id}`, updated )
      .then( r => {
        props.close();
      })
      .catch( e => {
        console.error( e );
        setErr( e.message || e );
      })
    }

    else if ( canSubmit ) {
      postRequest( '/api/passwords', { title, username, password, challenge1, challenge2, notes, email, accountno, url })
      .then( r => {
        if ( r.added ) props.close();
        else {
          setErr( r.message );
          console.error( r.message );
        }
      })
      .catch( e => {
        console.error( e );
        setErr( e.message || e );
      })
    }
  }

  const handleDelete = ( e: React.MouseEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    if ( props.item ) {
      deleteRequest( `/api/passwords/${props.item.id}` )
      .then( r => {
        props.close();
      })
      .catch( e => {
        console.error( e );
        setErr( e.message || e );
      })
    }
  };

  const clearError = () => {
    setErr('');
  }


  return(
    <div className={css.wrap} onClick={handleCancelBackdrop}>
      <div className={css.dialog} onClick={handleStopBubble}>

        <div className={css.title}>{ props.item ? 'Edit Password Item' : 'Create New Password Item'}</div>
        
        <div className={css.inputlabel}>Title:</div>
        <div className={css.input}><input type="text" id="title" value={title} onChange={handleChange}/></div>

        <div className={css.inputlabel}>Url:</div>
        <div className={css.input}><input type="text" id="url" value={url} onChange={handleChange} /></div>

        <div className={css.inputlabel}>Username:</div>
        <div className={css.input}><input type="text" id="username" value={username} onChange={handleChange} /></div>

        <div className={css.inputlabel}>Password:</div>
        <div className={css.input}><input type="text" id="password" value={password} onChange={handleChange} /></div>

        <div className={css.inputlabel}>Account No:</div>
        <div className={css.input}><input type="text" id="accountno" value={accountno} onChange={handleChange} /></div>

        <div className={css.inputlabel}>Challenge 1:</div>
        <div className={css.input}><input type="text" id="challenge1" value={challenge1} onChange={handleChange} /></div>

        <div className={css.inputlabel}>Challenge 2:</div>
        <div className={css.input}><input type="text" id="challenge2" value={challenge2} onChange={handleChange} /></div>

        <div className={css.inputlabel}>Email:</div>
        <div className={css.input}><input type="text" id="email" value={email} onChange={handleChange} /></div>

        <div className={css.inputlabel}>Notes:</div>
        <textarea  className={css.inputtext} id="notes-text" value={notes} onChange={handleTextChange} />

        <div className={css.but}>
          <input type="button" value="Cancel" onClick={handleCancel}/>
          { props.item && <input type="button" value="Delete" onClick={handleDelete}/>}
          <input type="button" disabled={!canSubmit} value="Submit" onClick={handleSubmit}/>
        </div>

        
      </div>
      { err && <ErrorDlg error={err} close={clearError}/>}
    </div>
  );
}

export default PasswordItem;