import { createUseStyles } from 'react-jss';

interface ErrorProps {
  error: string;
  close: () => void;
}

const useStyles = createUseStyles({
  wrap: {
    position: 'fixed',
    zIndex: 1,
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    backgroundColor: 'var(--color-bkgrnd-salmon-hover)',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  dialog: {
    margin: '0px auto',
    maxWidth: '500px',
    minWidth: '200px',
    maxHeight: 'calc(100% - 120px)',
    backgroundColor: 'var(--color-bkgrnd-salmon)',
    border: '1px solid var(--color-bkgrnd-pink)',
    borderRadius: '5px',
    padding: '25px',
    overflowY: 'scroll',
    position: 'relative'
  },
  close: {
    position: 'absolute',
    height: '20px',
    width: '20px',
    lineHeight: '20px',
    top: '0px',
    right: '0px',
    borderRadius: '50%',
    border: '1px solid var(--color-bkgrnd-pink)',
    backgroundColor: 'var(--color-bkgrnd-yellow)',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'var(--color-bkgrnd-pink)',
    },
    cursor: 'pointer',
    '& span': {
      display: 'inline-block',
      transform: 'translateY(-2px)'
    }

  },
  spacer: {
    flexGrow: 1
  }
});


function ErrorDilg( props: ErrorProps ) {
  const css = useStyles();
  return (
    <div className={css.wrap}>
      <div className={css.spacer}></div>
      <div className={css.dialog}>
        <div>{ props.error }</div>
        <div className={css.close} onClick={props.close }><span>x</span></div>
      </div>
      <div className={css.spacer}></div>
    </div>
  );
}

export default ErrorDilg;