
import React, { useState, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';

import { getRequest } from '../../req';
import PasswordItem, { PasswordsInterface } from './PasswordItem';

interface PasswordProps {}

const useStyles = createUseStyles({
  wrap: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '50px',
    lineHeight: '50px',
    '& input': {
      height: '26px',
      fontSize: '18px'
    },
    alignItems: 'center'
  },
  table: {
    minWidth: '100%',
    borderCollapse: 'collapse',
    '& th': {
      textAlign: 'left',
      borderBottom: '1px solid var(--color-bkgrnd-salmon)'
    },
    '& tr': {
      lineHeight: '26px',
      '&:nth-child(even)': {
        backgroundColor: 'var(--color-bkgrnd-salmon-hover)'
      }
    },
    marginBottom: '15px'
  },
  clear: {
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'center',
    border: '1px solid var(--color-bkgrnd-salmon)',
    '&:hover': {
      backgroundColor: 'var(--color-bkgrnd-salmon-hover)'
    },
    marginLeft: '4px',
    cursor: 'pointer',
  },
  spacer: {
    flexGrow: 1
  },
  add: {
    borderRadius: '5px',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'center',
    border: '1px solid var(--color-bkgrnd-salmon)',
    '&:hover': {
      backgroundColor: 'var(--color-bkgrnd-salmon-hover)'
    },
    paddingLeft: '10px',
    paddingRight: '10px',
    marginLeft: '4px'
  }
});


function Passwords( props: PasswordProps ) {
  const css = useStyles();
  const [ pswds, setPswds ] = useState<PasswordsInterface[]>( [] );
  const [ err, setErr ] = useState( '' );
  const [ filter, setFilter ] = useState( '' );
  const [ edit, setEdit ] = useState( false );
  const [ item, setItem ] = useState<undefined | PasswordsInterface>( undefined )

  const isMounted = useRef( false );

  useEffect(() => {
    isMounted.current = true;
    getPswds();
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    getPswds();
  }, [filter]);



  const getPswds = () => {
    getRequest( `/api/passwords?filter=${filter}`)
    .then( r => {
      if ( r.passwords ) {
        isMounted.current && setPswds( r.passwords );
      }
    })
    .catch( e => {
      console.error( e );
      isMounted.current && setErr( e.message || e );
    });
  };

  const handleFilterChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    setFilter( e.target.value );
  };

  const handleClearFilter = ( e: React.MouseEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    setFilter( '' );
  };

  const handleAdd = ( e: React.MouseEvent<HTMLInputElement> ) => {
    e.stopPropagation();
    e.preventDefault();
    setEdit( true );
  };

  const closeEdit = () => {
    setItem( undefined );
    setEdit( false );
    getPswds();
  };

  const renderTableItems = () => {
    return pswds.map(( p ) => {

      const handleEditItem = ( e: React.MouseEvent<HTMLTableCellElement> ) => {
        e.stopPropagation();
        e.preventDefault();
        setItem( p );
        setEdit( true );
      };

      return <tr key={`pswd-entry-${p.id}`} id={`pswd-id-${p.id}`}>
        <td>{p.title}</td>
        <td>{p.url}</td>
        <td>{p.username}</td>
        <td>{p.password}</td>
        <td onClick={handleEditItem}> Edit </td>
      </tr>
    });
  };


  return (
    <div className={css.wrap}>
      <div className={css.toolbar}>
        <div>Passwords</div>
        <div className={css.add } onClick={handleAdd}> Add </div>
        <div className={css.spacer}></div>
        <div>Filter:</div> 
        <input type="text" value={filter} onChange={handleFilterChange}/>
        <div className={css.clear} onClick={handleClearFilter}>X</div>
      </div>
      <div> 
        <table className={css.table}>
          <thead>
            <tr>
              <th>Title</th>
              <th>URL</th>
              <th>Username</th>
              <th>Password</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {renderTableItems()}
          </tbody>
        </table>
      </div>
      {err && <div>{err}</div>}
      {edit && <PasswordItem close={closeEdit} item={item}/>}
    </div>
  )
}

export default Passwords;