import { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import { Props } from '../utils';
import { deleteRequest, getRequest, postRequest } from '../req';
import logo from '../logo.png'; 
import Login from './Login';
import Passwords from './passwords/Passwords';
import Illyriad from './Illyriad';
import Home from './Home';
import ErrorDlg from './ErrorDlg';


const useStyles = createUseStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    color: 'var(--color-text)',

  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    height: '50px',
    lineHeight: '50px',
    backgroundColor: 'var(--color-bkgrnd-yellow)',
    fontSize: '30px',
  },
  logo: {
    width: '50px',
    height: '50px',
    marginRight: '5px',
    marginLeft: '2px'
  },
  body: {
    flexGrow: 1

  },
  footer: {
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
    backgroundColor: 'var(--color-bkgrnd-yellow)'
  },
  menubutton: {
    fontSize: '18px',
    '&:hover': {
      color: 'var(--color-text-2)'
    },
    marginRight: '5px',
    marginLeft: '2px',
  },

  menusection: {
    position: 'relative',
    height: '0px'
  },
  menu: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    fontSize: '18px',
    backgroundColor: 'var(--color-bkgrnd-yellow)',
    '& li': {
      height: '32px',
      lineHeight: '32px',
      listStyleType: 'none',
      borderTop: '1px solid var(--color-text)',
      '&:hover': {
        color: 'var(--color-text-2)'
      },
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    '&:last-child': {
      borderBottom: '1px solid var(--color-text)',
    },
    marginight: '5px',
    marginLeft: '2px',

  },

  spacer: {
    flexGrow: 1
  }

 
  /* an example on how to have animation with keyframes
  somecalss: {
    animation: '$applogospin infinite 20s linear',
  }
  
  '@keyframes applogospin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)'
    }
  }
  */
});

function Content( props: Props<any> ) {
  const history = useHistory();
  const css = useStyles();
  const [ open, setOpen ] = useState( false );
  const [ loggedIn, setLoggedIn ] = useState( false );
  const [ err, setErr ] = useState( '' );
  const [ vers, setVers] = useState( '' );

  useEffect(() => {
    initialize();
  }, []);

  const initialize = () => {
    getRequest( '/api/init' )
    .then( r => {
      if ( r.loggedIn ) {
        setLoggedIn( true );
        history.push( '/' );
      }
      else {
        setLoggedIn( false );
      }
      setVers( r.version );
    })
    .catch( e => {
      console.error(e);
      setLoggedIn( false );
    });
  }

  const openCloseMenu = () => {
    setOpen(!open);
  };

  const navto = ( path: string ) => {
    if ( path === '/login') {
      deleteRequest( '/api/login' )
      .catch( console.error)
      .finally( () => {
        setOpen( false );
        setLoggedIn( false );
        history.push( '/login' );
      });
    }
    else {
      history.push( path );
      setOpen( false );
    }
  };

  const login = ( username: string, password:string ) => {
    postRequest( '/api/login', { username, password})
      .then( r => {
        if ( r.loggedIn ) {
          setLoggedIn( true );
          setErr( '' );
          history.push( '/passwords' );
        }
        else {
          setErr( r.message|| r );
        }
      })
      .catch( e => {
        console.log( e )
        const message = (e && (e.message || e)) || 'Error logging in.';
        setErr( message );
      });
  }

  const clearError = () => {
    setErr('');
  }

  return (
    <div className={css.page}>
      {/* Header starts here */}
      <div className={css.header}>
        <div> <img src={logo} className={css.logo} alt="logo" /> </div>
        <div> Data Management </div>
        <div className={css.spacer}></div>
        {loggedIn && <div onClick={openCloseMenu} className={css.menubutton}>Menu</div>}
      </div>

      { open && 
      <div className={css.menusection}>
        <div className={css.menu}>
            <li onClick={() => navto('/login')}>Logout</li>
            <li onClick={() => navto('/passwords')}>Passwords</li>
            <li onClick={() => navto('/illyriad')}>Illyriad</li>
        </div>
      </div>}

      {/* Content of page here */}
      <div className={css.body}>
        
        <Switch>
          <Route exact path="/login" render={() => <Login login={login} {...props}/>}/>
          { !loggedIn && <Redirect to="/login"/> }

          <Route exact path="/" render={() => <Home {...props}/>}/>
          <Route path="/passwords" render={() => <Passwords {...props}/>}/>
          <Route path="/illyriad" render={() => <Illyriad {...props}/>} />
          <Redirect to="/"/>
        </Switch>
        

      </div>

      {/* Footer here */}
      <div className={css.footer}>
      Data Management {vers} <a href="https://hiteshlala.com"> &#169; {`${ new Date().getFullYear() === 2021 ? '2021' : '2021 - ' + new Date().getFullYear()} Hitesh Lala`}</a> 

      </div>
      { err && <ErrorDlg error={err} close={clearError}/> }

    </div>
  );
}

function App() {
  // did this so I have the history context in Content
  return (
    <BrowserRouter>
      <Content />
    </BrowserRouter>
  );
}

export default App;
