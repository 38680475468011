import {  Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

interface HomeProps {}

const useStyles = createUseStyles({
  wrap: {
    marginLeft: '15px',
    marginRight: '15px',
  }
});

function Home( props: HomeProps ) {
  const css = useStyles();
  return (
    <div className={css.wrap}>
      <li><Link to="/passwords">Manage Passwords</Link></li>
      <li><Link to="/illyriad">Manage Illyriad</Link></li>
    </div>
  )
}

export default Home;